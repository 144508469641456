import { useContext, useEffect, useRef, useState } from "react";
import styles from "./HomeLibrary.css";
import classes from "classnames";
import loadable from "@loadable/component";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "@churchofjesuschrist/universal-env";
import { useMountEffect } from "../../../util/custom-hooks";

import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import Frame from "../../components/Frame/Frame";
import GeneralContext from "../../components/GeneralContext/GeneralContext";
import Loading from "../../components/Loading/Loading";
import TileGroup from "../../components/TileGroup/TileGroup";
import LibraryGridItems from "../../components/LibraryGridItems/LibraryGridItems";
import LibraryHeader from "../../components/LibraryHeader/LibraryHeader";
import Mask from "../../components/Mask/Mask";
import Notifications from "../../components/Notifications/Notifications";
import PanelPlatformFooter from "../../components/PanelPlatformFooter/PanelPlatformFooter";
import SidePanelManager from "../../components/SidePanelManager/SidePanelManager";
import ScrollLogic from "../../../util/template-component-scroll-utils";
import { isAppBase } from "../../../util/uri-utils";
import { Breadcrumbs } from "@churchofjesuschrist/eden-breadcrumbs";
import SmartLink from "../../components/SmartLink/SmartLink";
import { H1 } from "@churchofjesuschrist/eden-headings";
import { Text4 } from "@churchofjesuschrist/eden-text";

const { APP_URL } = get();

const VideoStream = loadable(
    () =>
        import(
            /* webpackChunkName: 'video-stream' */ "../../components/VideoStream/VideoStream"
        ),
    { fallback: <Loading className={styles.videoStreamPlaceholder} /> }
);

export const HomeLibrary = ({
    activePanel,
    additionalItems,
    featureItems,
    lang,
    library: {
        breadCrumbs = [],
        canonicalUrl,
        noIndex,
        sections,
        status,
        title,
        description = "",
        seoDescription,
        seoTitle,
        header,
    } = {},
    location = {},
    notifications,
    setActivePanel,
    showArchivedContent,
    streamItems,
    ...rest
} = {}) => {
    const { crossLinkMode } = useContext(GeneralContext);

    let toggleSidePanel = (panel) => {
        activePanel === panel ? setActivePanel(null) : setActivePanel(panel);
    };

    const [allowMask, setAllowMask] = useState(false);
    const [collectionSections, setCollectionSections] = useState(sections);
    const [forceMobile, setForceMobile] = useState(false);
    // We should only remove archived-content from sections after the browser has hydated the server rendering,
    // so we wait for the client rendering to conditionally remove the collection
    const [isClient, setIsClient] = useState(false);

    const scrollUtils = useRef();
    const defaultPanelRef = useRef();
    const history = useHistory();

    useMountEffect(() => {
        setIsClient(true);

        // activePanel mediaQueryList
        const footerMediaQueryList = window.matchMedia(
            "(min-width: 60em) and (max-width: 80em)"
        );
        const allowMaskMediaQueryList = window.matchMedia("(max-width: 60em)");

        setForceMobile(footerMediaQueryList.matches);
        setAllowMask(allowMaskMediaQueryList.matches);

        const handleFooterMediaQueryChange = (event) => {
            setForceMobile(event.matches);
        };
        const handleAllowMaskMediaQueryChange = (event) => {
            setAllowMask(event.matches);
        };

        footerMediaQueryList.addEventListener(
            "change",
            handleFooterMediaQueryChange
        );
        allowMaskMediaQueryList.addEventListener(
            "change",
            handleAllowMaskMediaQueryChange
        );

        return () => {
            footerMediaQueryList.removeEventListener(
                "change",
                handleFooterMediaQueryChange
            );
            allowMaskMediaQueryList.removeEventListener(
                "change",
                handleAllowMaskMediaQueryChange
            );
        };
    });

    useMountEffect(() => {
        scrollUtils.current = new ScrollLogic({
            history,
            defaultPanelRef,
            crossLinkMode,
        });

        return () => {
            scrollUtils.current?.cleanup();
        };
    });

    useEffect(() => {
        if (allowMask && activePanel) {
            scrollUtils.current?.scrollHeaderOffScreen();
        }
    }, [allowMask, activePanel]);

    useEffect(() => {
        if (isClient && !showArchivedContent) {
            // filter out archived-content
            setCollectionSections(
                sections?.map((section) => {
                    const filteredEntries = section?.entries?.filter(
                        (entry) => {
                            return entry.uri !== "/study/archived-content";
                        }
                    );

                    return { ...section, entries: filteredEntries };
                })
            );
        } else {
            setCollectionSections(sections);
        }
    }, [isClient, showArchivedContent, sections]);

    useEffect(() => {
        document.dispatchEvent(new CustomEvent("checkcontext"));

        setIsClient(true);
    }, []);

    let breadCrumbsList = breadCrumbs;

    let current = title;
    // Only add `current` to the list if it isn't already the last in the list
    if (breadCrumbsList?.at(-1)?.title !== current) {
        breadCrumbsList = [...breadCrumbsList, { title: current }];
    }

    return (
        <section
            className={classes(
                styles.libraryPageHome,
                activePanel && styles.sidePanelManagerOpen
            )}
        >
            {!crossLinkMode && (
                <Helmet>
                    <title>{seoTitle}</title>
                    {noIndex && <meta name="robots" content="noindex" />}
                    {canonicalUrl && (
                        <link
                            key="canonical"
                            rel="canonical"
                            href={`${APP_URL}${canonicalUrl}`}
                        />
                    )}
                </Helmet>
            )}
            <LibraryHeader className={styles.libraryHeader} />
            {!crossLinkMode && notifications && (
                <Notifications notifications={notifications} />
            )}
            {isAppBase(location?.pathname) && !crossLinkMode ? (
                <Frame
                    className={styles.contentWrapper}
                    type="narrowTop"
                    id="main"
                >
                    <>
                        {streamItems && streamItems.streamEnabled && (
                            <VideoStream
                                className={styles.heroVideoStream}
                                item={streamItems}
                            />
                        )}
                        {featureItems && (
                            <TileGroup
                                feature={featureItems.length}
                                items={featureItems}
                            />
                        )}
                        {additionalItems && (
                            <TileGroup
                                group="additionalTiles"
                                items={additionalItems}
                            />
                        )}
                    </>
                    {status >= 300 && !sections.length ? (
                        <ErrorPanel
                            className={styles.errorPanel}
                            id={"home"}
                            status={status}
                            {...rest}
                        />
                    ) : (
                        <div className={styles.gospelLibrarySection}>
                            {(featureItems?.length ||
                                additionalItems?.length ||
                                (streamItems && streamItems.streamEnabled)) && (
                                <div className={styles.statusTitle}>
                                    {title}
                                </div>
                            )}
                            <div className={styles.librarySEOHead}>
                                {(header || breadCrumbsList.length > 0) && (
                                    <H1>
                                        {header || breadCrumbsList.at(-1).title}
                                    </H1>
                                )}
                                {(description || seoDescription) && (
                                    <Text4>
                                        {description || seoDescription}
                                    </Text4>
                                )}
                            </div>
                            <LibraryGridItems
                                crossLinkMode={crossLinkMode}
                                lang={lang}
                                sections={collectionSections}
                            />
                        </div>
                    )}
                </Frame>
            ) : (
                <Frame
                    className={styles.contentWrapper}
                    type="narrowTop"
                    id="main"
                >
                    {status >= 300 && !sections.length ? (
                        <ErrorPanel
                            className={styles.errorPanel}
                            id={"home"}
                            status={status}
                            {...rest}
                        />
                    ) : (
                        <div className={styles.gospelLibrarySection}>
                            <div className={styles.librarySEOHead}>
                                {breadCrumbsList && (
                                    <Breadcrumbs>
                                        {breadCrumbsList?.map(
                                            ({ uri = "", title = "" }) => (
                                                // eden Breadcrumbs automatically gives each item a key based on its uri, but the last item doesn't have one.
                                                // eden will overwrite this key with its own, but this gets rid of the 'unique key' error.
                                                <SmartLink
                                                    href={uri}
                                                    key="homeBreadCrumb"
                                                >
                                                    {title}
                                                </SmartLink>
                                            )
                                        )}
                                    </Breadcrumbs>
                                )}
                                {(header || breadCrumbsList.length > 0) && (
                                    <H1>
                                        {header || breadCrumbsList.at(-1).title}
                                    </H1>
                                )}
                                {(description || seoDescription) && (
                                    <Text4>
                                        {description || seoDescription}
                                    </Text4>
                                )}
                            </div>
                            <LibraryGridItems
                                crossLinkMode={crossLinkMode}
                                lang={lang}
                                sections={collectionSections}
                            />
                        </div>
                    )}
                </Frame>
            )}
            <Mask
                active={allowMask && activePanel}
                className={classes(styles.sidePanelMask)}
                onClick={() => toggleSidePanel("")}
            />
            {!crossLinkMode && (
                <SidePanelManager
                    className={styles.sidePanelManager}
                    handleVirtualScroll={
                        scrollUtils.current?.handleVirtualScroll
                    }
                    page="library"
                />
            )}
            <PanelPlatformFooter
                className={styles.libraryFooter}
                forceMobile={activePanel && forceMobile}
            />
        </section>
    );
};

export default HomeLibrary;
